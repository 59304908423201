import { useEffect, useState } from "react";

type Posts = {
  id: number;
  title: string;
  body: string[];
  show: boolean;
};
const App = () => {
  const [dataPosts, setDataPosts] = useState<Posts[]>();
  const convertStringToHtml = (str: string[]) => {
    const articleContent = str.join(" ");
    // return str to html element
    return <div className="article-content" dangerouslySetInnerHTML={{ __html: articleContent }} />;
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch("https://www.ankibrasil.com/data/data.json");
      const resultJson = await result.json();
      setDataPosts(resultJson);
      console.log(resultJson);
    };
    fetchData();
  }, []);

  return (
    <div className="App">
      <h1>Blog</h1>
      {dataPosts?.map((post) => (
        <div key={post.id}>
          <h2>{post.title}</h2>
          <>{convertStringToHtml(post.body)}</>
        </div>
      ))}
    </div>
  );
};

export default App;
